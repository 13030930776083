<template>
  <v-footer height="auto" color="#181818">
    <v-layout justify-center row wrap>
      <v-btn
        v-for="link in links"
        :key="link.name"
        :href="link.href"
        color="white"
        flat
        round
      >
        {{ link.name }}
      </v-btn>

      <v-flex
        black
        lighten-2
        py-3
        text-xs-center
        white--text
        xs12
      >
        &copy;{{ new Date().getFullYear() }} — <strong>DrogonTV</strong>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [
        { name: 'Sign In',    href: '/signin' },
        // { name: 'Sign Up',    href: '//rapidvps.is/amember/signup' },
        { name: 'Contact Us', href: "mailto:admin@drogon.tv" }
      ]
    })
  }
</script>

<style>
</style>
